import React, { useContext, useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import "../../assetss/scss/Header.scss";
import Logo from "../../assetss/img/logo.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { CommonContext } from "../../context";
import { Helmet } from "react-helmet-async";
// import { Helmet } from "react-helmet-async";

const Header = () => {
  const { indexData, seoData, user, handleLogout } = useContext(CommonContext);
  const { slug } = useParams();
  const location = useLocation();


  const formatText = (text) => {
    if (!text) return '';
    const cleanedText = text.replace(/[^a-zA-Z0-9\s]/g, ' ').replace(/\s+/g, ' ');
    return cleanedText.trim().charAt(0).toUpperCase() + cleanedText.trim().slice(1).toLowerCase();
  };

  const Logo =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "logo")
      ?.option_value;
  const favicon =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "favicon")
      ?.option_value;

  const fallbackSlug = formatText(slug || location.pathname.split('/').pop());
  const siteTitle = seoData?.data?.title || fallbackSlug;
  const siteKeywords = seoData?.data?.keywords || fallbackSlug;
  const siteDescription = seoData?.data?.description || fallbackSlug;


  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({});
  const handleNavClick = (path) => {
    setActiveLink(path);
  };
  const [activeLink, setActiveLink] = useState("/");
  // New state for play/pause control
  const [isPaused, setIsPaused] = useState(false);

  //  Function to toggle play/pause

  const handlePlayPause = () => {
    setIsPaused((prev) => !prev);
  };
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    if (favicon) {
      const faviconLink = document.querySelector("link[rel='icon']");
      if (faviconLink) {
        faviconLink.href = `${indexData?.path}/${favicon}`;
      }
    }
  }, [favicon]);
  useEffect(() => {
    // Set the page title
    document.title = siteTitle;

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", siteDescription);
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content = siteDescription;
      document.head.appendChild(newMetaDescription);
    }
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.setAttribute("content", siteKeywords);
    } else {
      // Create the meta tag if it doesn't exist
      const newMetaKeywords = document.createElement("meta");
      newMetaKeywords.name = "keywords";
      newMetaKeywords.content = siteKeywords;
      document.head.appendChild(newMetaKeywords);
    }
  }, [siteTitle, siteDescription, siteKeywords]); // Run once on component mount
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);


  const toggleSubMenu = (menu, isOpen) => {
    setIsSubMenuOpen((prev) => ({ ...prev, [menu]: isOpen }));
  };
  return (
    <>
      <div
        className={`overlay ${isMenuOpen ? "active" : ""}`}
        onClick={toggleMenu}
      ></div>

      <div className="top-strip">
        <div className={`top-strip-marquee ${isPaused ? "paused" : ""}`}>
          <div className="marquee-content">
            {indexData?.alerts?.title || "Welcome to our website!"}
          </div>
        </div>
        <Nav color="light" light expand="md" >
          <span className="play-pause-icon" onClick={handlePlayPause}>
            {isPaused ? "►" : "⏸"}
          </span>
          <NavItem>
            <Link className="nav-link" to="https://institutionofvaluers.net/ekta/">
              Ekta
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/tender">
              Tender
            </Link>
          </NavItem>
          {user ? (
            <UncontrolledDropdown nav inNavbar className="profile-dropdown">
              <DropdownToggle nav caret className="profile-dropdown-toggle">
                <div className="profile-circle">
                  {user.name?.charAt(0).toUpperCase()}
                </div>
              </DropdownToggle>
              <DropdownMenu right className="profile-dropdown-menu">
                <div className="profile-name">{user.name}</div>
                <DropdownItem>
                  <Link to="/profile">My Profile</Link>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            <>
              <NavItem>
                <Link className="nav-link" to="/signin">
                  Login
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/signup">
                  Register
                </Link>
              </NavItem>
            </>
          )}
          {/* <NavItem>
            <Link className="nav-link" to="/signin">
              Login
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/signup">
              Register
            </Link>
          </NavItem> */}
        </Nav>
      </div>

      <Navbar color="light" light expand="md" className="custom-navbar">
        <NavbarBrand href="/">
          <img src={`${indexData?.settings_path}/${Logo}`} alt="IOV Logo" className="logo" />
        </NavbarBrand>
        <div className="ml-auto d-md-none">
          <FaBars onClick={toggleMenu} className="menu-icon" />
        </div>
        <Nav className="ml-auto d-none d-md-flex" navbar>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/" ? "active" : ""}`} to="/" onClick={() => handleNavClick("/")}>Home</Link>
          </NavItem>
          <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("about", true)}
            onMouseLeave={() => toggleSubMenu("about", false)}
            isOpen={isSubMenuOpen["about"]}
            className={`nav-item ${["/page/about", "/page/about-founder", "/page/about-secretary", "/page/council", "/blue-book"].includes(activeLink) ? 'active-dropdown' : ''}`}
          >
            <DropdownToggle nav caret>About</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem><Link to="/page/about" className={`dropdown-item ${activeLink === '/page/about' ? 'active' : ''}`} onClick={() => handleNavClick("/page/about")}>Who We Are</Link></DropdownItem>
              <DropdownItem><Link to="/page/about-founder" className={`dropdown-item ${activeLink === '/page/about-founder' ? 'active' : ''}`} onClick={() => handleNavClick("/page/about-founder")}>About Founder</Link></DropdownItem>
              <DropdownItem><Link to="/page/about-secretary" className={`dropdown-item ${activeLink === '/page/about-secretary' ? 'active' : ''}`} onClick={() => handleNavClick("/page/about-secretary")}>Hon. Gen. Secretary Desk</Link></DropdownItem>
              <DropdownItem><Link to="/page/council" className={`dropdown-item ${activeLink === '/page/council' ? 'active' : ''}`} onClick={() => handleNavClick("/page/council")}>Council 2022-2024</Link></DropdownItem>
              <DropdownItem><Link to="/blue-book" className={`dropdown-item ${activeLink === '/blue-book' ? 'active' : ''}`} onClick={() => handleNavClick("/blue-book")}>Blue Book</Link></DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("join", true)}
            onMouseLeave={() => toggleSubMenu("join", false)}
            isOpen={isSubMenuOpen["join"]}
            className={`nav-item ${["/profile"].includes(activeLink) ? 'active-dropdown' : ''}`}
          >
            <DropdownToggle nav caret>Join IOV</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem><Link to={user ? "/profile" : "/signup"} className={`dropdown-item ${activeLink === '/profile' ? 'active' : ''}`} onClick={() => handleNavClick("/profile")}>Individual Membership</Link></DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/courses" ? "active" : ""}`} to="/courses" onClick={() => handleNavClick("/courses")}>Courses</Link>
          </NavItem>
          <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("grievance", true)}
            onMouseLeave={() => toggleSubMenu("grievance", false)}
            isOpen={isSubMenuOpen["grievance"]}
            className={`nav-item ${["/page/about-committee", "/page/objective", "/grievance"].includes(activeLink) ? 'active-dropdown' : ''}`}
          >
            <DropdownToggle nav caret>Grievance</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <Link to="/page/about-committee" className={`dropdown-item ${activeLink === '/page/about-committee' ? 'active' : ''}`}> About Committee </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/page/objective" className={`dropdown-item ${activeLink === '/page/objective' ? 'active' : ''}`}> Objective</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/grievance" className={`dropdown-item ${activeLink === '/grievance' ? 'active' : ''}`}> File A Grievance</Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/publications" ? "active" : ""}`} to="/publications" onClick={() => handleNavClick("/publications")}>Publications</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/event" ? "active" : ""}`} to="/event" onClick={() => handleNavClick("/event")}>Event</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/branches" ? "active" : ""}`} to="/branches" onClick={() => handleNavClick("/branches")}>Branches</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/contact" ? "active" : ""}`} to="/contact" onClick={() => handleNavClick("/contact")}>Contact</Link>
          </NavItem>
        </Nav>
      </Navbar>
      <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
        <div className="mobile-menu-header">
          <img src="https://backend.institutionofvaluers.net/api/img/settings/logo-907969.png" alt="IOV Logo" className="logo" />
          <FaTimes onClick={toggleMenu} className="close-icon" />
        </div>
        <Nav navbar>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/" ? "active" : ""}`} to="/" onClick={toggleMenu}>
              Home
            </Link>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("about", true)}
              onMouseLeave={() => toggleSubMenu("about", false)}
            >
              About
            </DropdownToggle>
            <DropdownMenu
              right
              className={isSubMenuOpen["about"] ? "show" : ""}
            >
              <DropdownItem onClick={toggleMenu}>
                <Link to="/page/about" className={`dropdown-item ${activeLink === '/page/about' ? 'active' : ''}`}> Who We Are </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link to="/page/about-founder" className={`dropdown-item ${activeLink === '/page/about-founder' ? 'active' : ''}`}> About Founder </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link to="/page/about-secretary" className={`dropdown-item ${activeLink === '/page/about-secretary' ? 'active' : ''}`}> Hon. Gen. Secretary Desk </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link to="/page/council" className={`dropdown-item ${activeLink === '/page/council' ? 'active' : ''}`}> Council 2022-2024 </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link to="/blue-book" className={`dropdown-item ${activeLink === '/blue-book' ? 'active' : ''}`}> Blue Book </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("join", true)}
              onMouseLeave={() => toggleSubMenu("join", false)}
            >
              Join IOV
            </DropdownToggle>
            <DropdownMenu right className={isSubMenuOpen["join"] ? "show" : ""}>
              <DropdownItem
                onClick={toggleMenu}
              >
                <Link to={user ? "/profile" : "/signup"} className={`dropdown-item ${activeLink === '/profile' ? 'active' : ''}`}> Individual Membership </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link
              className="nav-link"
              to="/courses"
              onClick={toggleMenu}
            >
              Courses
            </Link>
          </NavItem>
          {/* <NavItem>
            <Link className="nav-link" to="/grievance" onClick={toggleMenu}>
              Grievance
            </Link>
          </NavItem> */}
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("grievance", true)}
              onMouseLeave={() => toggleSubMenu("grievance", false)}
            >
              Grievance
            </DropdownToggle>
            <DropdownMenu right className={isSubMenuOpen["grievance"] ? "show" : ""}>
              <DropdownItem
                onClick={toggleMenu}
              >
                <Link to="/page/about-committee" className={`dropdown-item ${activeLink === '/page/about-committee' ? 'active' : ''}`}> About Committee </Link>
              </DropdownItem>
              <DropdownItem
                onClick={toggleMenu}
              >
                <Link to="/page/objective" className={`dropdown-item ${activeLink === '/page/objective' ? 'active' : ''}`}>
                  Objective
                </Link>
              </DropdownItem>
              <DropdownItem
                onClick={toggleMenu}
              >
                <Link to="/grievance" className={`dropdown-item ${activeLink === '/grievance' ? 'active' : ''}`}> File A Grievance </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/publications" ? "active" : ""}`} to="/publications" onClick={toggleMenu}>
              Publications
            </Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/event" ? "active" : ""}`} to="/event" onClick={toggleMenu}>
              Event
            </Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/branches" ? "active" : ""}`} to="/branches" onClick={toggleMenu}>
              Branches
            </Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/contact" ? "active" : ""}`} to="/contact" onClick={toggleMenu}>
              Contact
            </Link>
          </NavItem>
        </Nav>
      </div>
    </>
  );
};

export default Header;
