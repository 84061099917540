import React, { createContext, useEffect, useState } from 'react';
import commonApi from '../utiles/api';

export const PublicationsContext = createContext();

export function PublicationsContextProvider({ children }) {


    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [publicationsCount, setPublicationsCount] = useState('');

    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('home/publications-cards');
            if (response.data) {
                setPublicationsCount(response?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        onLoadApi();
    }, []);
    const contextValue = {
        publicationsCount,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <PublicationsContext.Provider value={contextValue}>{children}</PublicationsContext.Provider>;
}
