import React, { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';
import pdfFile from '../../assetss/img/IOV_Blue_Book.pdf';
import { Button, Card, Col } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PdfFlip = () => {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  console.log('location', location);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkMobile);
    checkMobile();

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
  useEffect(() => {
    const loadPDF = async () => {
      const loadingTask = pdfjsLib.getDocument({
        url: `${process.env.REACT_APP_BASE_URL}${location.state}`,
      });

      const pdf = await loadingTask?.promise;
      const totalPages = pdf?.numPages;
      const pagesArray = [];
      let loadedPages = 0;

      for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: context, viewport }).promise;
        pagesArray.push(canvas.toDataURL());

        // Update progress percentage
        loadedPages++;
        setProgress(Math.round((loadedPages / totalPages) * 100));
      }

      // Ensure total pages are even for desktop view
      if (pagesArray.length % 2 !== 0) {
        pagesArray.push(null); // Add a blank page for pairing
      }

      setPages(pagesArray);
      setLoading(false);
    };

    loadPDF();
  }, []);

  const totalPairs = isMobile ? pages.length : Math.ceil(pages.length / 2);

  const handleNext = () => {
    if (currentPage < totalPairs - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (event) => {
    const pageNumber = parseInt(event.target.value, 10);
    if (pageNumber > 0 && pageNumber <= totalPairs) {
      setCurrentPage(pageNumber - 1);
    }
  };

  return (
    <>
      <Button color='secondary' onClick={() => navigate(-1)} className='back-btn'>&lt; Back</Button>
      {loading ? (
        <div className="loading-container">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}>
              {/* {progress}% */}
            </div>
          </div>
          <p>Loading... {progress}%</p>
        </div>
      ) : (
        <Card className="flipbook-card">
          <div className="flipbook-container">
            {isMobile ? (
              <div className="flipbook-page flip-animation">
                <img
                  src={pages[currentPage]}
                  alt={`Page ${currentPage + 1}`}
                  style={{ width: '100%' }}
                />
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '50%', textAlign: 'center' }}>
                  {pages[currentPage * 2] ? (
                    <img
                      src={pages[currentPage * 2]}
                      alt={`Page ${currentPage * 2 + 1}`}
                      style={{ width: '100%' }}
                    />
                  ) : null}
                </div>
                <div style={{ width: '50%', textAlign: 'center' }}>
                  {pages[currentPage * 2 + 1] ? (
                    <img
                      src={pages[currentPage * 2 + 1]}
                      alt={`Page ${currentPage * 2 + 2}`}
                      style={{ width: '100%' }}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>
          <div className="row mt-3">
            <Col md="4" style={{ marginTop: '10px' }}>
              <input
                type="number"
                min="1"
                max={totalPairs}
                placeholder="Go to page"
                onChange={handlePageChange}
                style={{ width: '80px' }}
                className="form-control w-100"
              />
            </Col>
            <Col md="8" style={{ marginTop: '20px' }} className="pagination-blue-book">
              <Button color="primary" outline onClick={handlePrev} disabled={currentPage === 0}>
                Previous
              </Button>
              <span style={{ margin: '0 10px' }}>
                Page {currentPage + 1} of {totalPairs}
              </span>
              <Button
                color="primary"
                outline
                onClick={handleNext}
                disabled={currentPage === totalPairs - 1}
              >
                Next
              </Button>
            </Col>
          </div>
        </Card>
      )}
    </>
  );
};

export default PdfFlip;
