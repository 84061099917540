import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import commonApi from '../utiles/api';

export const BookContext = createContext();

export function BookContextProvider({ children }) {
    const location = useLocation();

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [bookData, setBookData] = useState([]);
    const [bookDetail, setBookDetail] = useState([]);


    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('books/list');
            if (response.data.s === 1) {
                setBookData(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDetailApi = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('books/front/view', { id: id });
            if (response.data.s === 1) {
                setBookDetail(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        onLoadApi();
    }, []);
    useEffect(() => {
        if (location?.state) {
            onDetailApi(location.state?.id);
        }
    }, [location]);

    const contextValue = {
        bookData,
        bookDetail,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <BookContext.Provider value={contextValue}>{children}</BookContext.Provider>;
}
