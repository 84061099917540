import React from 'react';
import { Container, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import '../../assetss/scss/notFound.scss';
import { Link } from 'react-router-dom'; 

const NotFound = () => {
    return (
        <div className="not-found">
            <Container className="not-found-content">
                <FontAwesomeIcon icon={faExclamationTriangle} className="fa-icon" />
                <h1>404</h1>
                <p>Oops! The page you're looking for doesn't exist.</p>
                <Button color="primary" tag={Link} to="/">
                    Go Back Home
                </Button>
            </Container>
        </div>
    );
};

export default NotFound;
